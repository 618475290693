import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Support, SupportResponse } from '../models/support';
import { Observable } from 'rxjs';
import { WithDrawAutomateRequest } from '../models/withdraw';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private API_URL = `${environment.backendURL}/support`;

  constructor(private http: HttpClient) { }

  updateSupportAndCallback(support: Support) {
    return this.http.post<any>(`${this.API_URL}/update/callback`, support);
  }

  updateSupport(support: Support) {
    return this.http.post<any>(`${this.API_URL}/update`, support);
  }

  createSupport(support: Support) {
    return this.http.post<any>(`${this.API_URL}`, support);
  }

  checkDuplicateRoute(support: Support) {
    return this.http.post<any>(`${this.API_URL}/check`, support);
  }

  checkDuplicateSupportByDfTxnId(dfTransactionId: string) {
    const params = new HttpParams()
      .set('dfTransactionId', dfTransactionId)
    return this.http.get<any>(`${this.API_URL}/checkTxnId`, { params });
  }

  refundsSupport(wd: WithDrawAutomateRequest, id: number, description: string) {
    return this.http.post<any>(`${this.API_URL}/refunds?id=${id}&description=${description}`, wd);
  }

  searchSupport(sDate: string, eDate: string, accountNo: string, status: string,
    id: string, username: string, page: number, pageSize: number): Observable<SupportResponse> {
    const params = new HttpParams()
      .set('sDate', sDate)
      .set('eDate', eDate)
      .set('id', id === '' ? '-1' : id.toString())
      .set('status', status)
      .set('page', page.toString())
      .set('username', '%' + username + '%')
      .set('pageSize', pageSize.toString())
    return this.http.get<SupportResponse>(`${this.API_URL}`, { params });
  }

  userSearchSupport(sDate: string, eDate: string, jwt: string, status: string, id: string, page: number, pageSize: number): Observable<SupportResponse> {
    const params = new HttpParams()
      .set('sDate', sDate)
      .set('eDate', eDate)
      .set('id', id == '' ? '-1' : id.toString())
      .set('status', status)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
    return this.http.post<SupportResponse>(`${this.API_URL}/user/search`, { data: jwt }, { params });
  }

  downloadExcel(sDate: string, eDate: string, accountNo: string, status: string, id: string, page: number, pageSize: number): Observable<Blob> {
    const params = new HttpParams()
      .set('sDate', sDate)
      .set('eDate', eDate)
      .set('id', id == '' ? '-1' : id.toString())
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
    return this.http.get(`${this.API_URL}/excel`, { params, responseType: 'blob' });
  }


}
