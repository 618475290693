<div class="row justify-content-center">
  <div class="col-auto">
    <!-- <ngx-spinner bdOpacity=0.9 bdColor="#333" size="medium" color="#fff" type="ball-atom" [fullScreen]="true">
            <p style="color: white"> ตรวจสอบการโอนเงิน </p>
        </ngx-spinner> -->
    <div class="wrap">
      <div class="box">
        <div class="text-center">
          <label id="secureonlinepayment">
            <img
              src="../assets/images/QR-PAY-LOGO.png"
              class="img-fluid text-center"
              width="100%"
          /></label>
        </div>
        <div class="m-r-20">
          <h4 class="">Hello !!! {{ payment?.username }}</h4>
        </div>
        <div class="m-r-20">
          <h5 class="">{{ payment?.name }} - {{ payment?.lastname }}</h5>
        </div>
        <hr />
        <div class="top">
          <div class="details">
            <div class="row">
              <div class="col-12 text-center">
                <label id="transactiontime">ยอดเงินที่ต้องโอน</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <label id="transactiontime"
                  >จำนวน {{ payment?.amount }} บาท</label
                >
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="content">
          <div class="row">
            <div class="col-12">
              <h2 style="font-weight: normal; font-size: 14px">
                จาก บัญชีของคุณ ที่แสดงตามด้านล่างนี้เท่านั้น
              </h2>
            </div>
          </div>
          <div class="row" *ngFor="let b of payment?.bankAccounts">
            <div class="col-12">
              <div class="row">
                <div class="col-10">
                  <img
                    src="../../assets/bank-logo/{{ b.bankName }}.png"
                    style="width: 25px; height: 25px"
                  />{{ b.accountNumber }}
                </div>
              </div>
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <span style="color: red">
                <h4>
                  <b>กรุณาอย่ากดรีเฟรชหรือเปลี่ยนหน้าจอระหว่างทำรายการ</b>
                </h4>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <div class="value">
                <h4>
                  ภายในเวลา {{ timeDisplay }}<b><span id="resultAPI"></span></b>
                </h4>
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div
              style="width: 100%; height: auto;"
              #qrcode
            ></div>
          </div>
        </div>
      </div>

      <div class="footnote">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center">
              <span>
                <h5><b>สแกน QR เพื่อโอนเงิน</b></h5>
              </span>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3 class="text-center">
              <span style="color: red">
                <h4><b>มิฉะนั้น ระบบจะไม่สามารถเติมเงินให้ท่านได้</b></h4>
              </span>
            </h3>
            <!-- <h3 class="text-center">
                            <span>
                                <h4><b>*กรุณาอย่ากดรีเฟรชหน้าจอ มิฉะนั้นท่านจะไม่สามารถฝากเงินเข้าระบบได้</b></h4>
                            </span>
                        </h3> -->
          </div>
        </div>
      </div>
    </div>

    <ng-template #alert>
      <div class="modal-header" style="display: block">
        <div class="row">
          <div class="col-12 text-center">
            <img
              src="../assets/images/QR-PAY-LOGO.png"
              class="img-fluid"
              width="45%"
            />
          </div>
        </div>
        <div class="row" *ngIf="!error">
          <div class="col-12">
            <h4 class="">Hello !!! {{ payment?.username }}</h4>
          </div>
        </div>
        <div class="row" *ngIf="!error">
          <div class="col-12">
            <h5 class="">{{ payment?.name }} - {{ payment?.lastname }}</h5>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="error">
        <span style="color: red">{{ error }}</span>
      </div>
      <div class="modal-body" *ngIf="!error">
        <div class="row">
          <div class="col-12">
            <h4 class="text-center">ยอดเงินที่ต้องโอน</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="text-center">จำนวน {{ payment?.amount }} บาท</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="text-center">
              ต้องใช้บัญชีที่แสดงด้านล่างนี้ในการฝากเงินเท่านั้น
            </h4>
          </div>
        </div>
        <div class="row" *ngFor="let b of payment?.bankAccounts">
          <div class="col-12">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-10">
                <img
                  src="../../assets/bank-logo/{{ b.bankName }}.png"
                  style="width: 25px; height: 25px"
                />{{ b.accountNumber }}
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h3 class="text-center">
              <span style="color: red">
                <h4><b>มิฉะนั้น ระบบจะไม่สามารถเติมเงินให้ท่านได้</b></h4>
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="display: block" *ngIf="!error">
        <div class="row justify-content-center">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-success"
              (click)="closeModal(true)"
              ngbAutofocus
            >
              ทำรายการ({{ modalTime }})
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
