import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  path = `${environment.backendURL}/login`;
  pathUser = `${environment.backendURL}/login/user`;
  pathCust = `${environment.backendURL}/customers`;

  constructor(private router: Router, private http: HttpClient) { }

  clear(): void {
    localStorage.clear();
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('token') != null && !this.isTokenExpired();
  }

  getToken(): string {
    return localStorage.getItem('token')
  }

  isTokenExpired(): boolean {
    const user: User = jwt_decode(localStorage.getItem('token'));
    if (user.exp > Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  decode(): User {
    if (!localStorage.getItem('token')) {
      return null;
    };
    const user: User = jwt_decode(localStorage.getItem('token'));
    return user;
  }

  getAllUser(): Observable<any> {
    return this.http.get(`${this.pathCust}`);
  }

  getDFUser(): Observable<any> {
    return this.http.get(`${this.path}/search/df`);
  }

  updateUser(user: User): Observable<any> {
    return this.http.post(`${this.path}/update`, user);
  }


  register(user: User): Observable<any> {
    return this.http.post(`${this.path}/register`, user, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  login(username: string, password: string, ipAddress: string) {
    return this.http.post(`${this.path}`, {
      username, password, ipAddress
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    });
  }

  loginUser(username: string, password: string) {
    return this.http.post(`${this.pathUser}`, {
      username, password
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    });
  }

  setRead() {
    this.http.post(`${this.path}/readed`, this.decode(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).toPromise().then(res => res);
  }

  getRead(): Observable<User> {
    return this.http.get<User>(`${this.pathCust}/get/${this.decode().id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  setStorage(token) {
    localStorage.setItem('token', token);
    this.router.navigate(['/manage']);
  }

  setStorageByname(key, value) {
    localStorage.setItem(key, value);
  }

  getStorageByname(key) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : '';
  }

  logout(): void {
    this.clear();
    this.router.navigate(['/manage/login']);
  }

  logoutUser(): void {
    this.clear();
    this.router.navigate(['/user/login']);
  }

}
