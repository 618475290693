<div class="row justify-content-center">
  <div class="col-auto" *ngIf="isDup">
    <label>
      <h4>รายการนี้ถูกสร้างในระบบเรียบร้อยแล้ว</h4>
    </label>
  </div>
</div>

<div class="row justify-content-center">
  <div class="col-auto" *ngIf="!isDup">
    <form [formGroup]="f">
      <div class="wrap">
        <div class="box">
          <div class="m-r-20 text-center">
            <label id="secureonlinepayment"> <img src="../assets/images/QR-PAY-LOGO.png"
                class="rounded-circle img-fluid text-center" width="100%"></label>
          </div>
          <div class="m-r-20 ">
            <label id="secureonlinepayment" *ngIf="flag == false">แจ้งปัญหา</label>
            <label for="">
              <h4 style="color:red" *ngIf="flag == true">
                แจ้งปัญหา
              </h4>
            </label>
          </div>
          <ngx-spinner bdOpacity=0.9 bdColor="#333" size="medium" color="#fff" type="ball-atom" [fullScreen]="true">
            <p style="color: white"> Loading </p>
          </ngx-spinner>
          <ngb-alert *ngIf="alerts != ''" [type]="alerts.type" (close)="close(alerts)">{{ alerts.message }}
          </ngb-alert>

          <div class="form-group">
            <label for="bank">กรุณาเลือกธนาคารของคุณ</label>
            <!-- <select name="bankName" id="bankName" class="form-control ml-2" formControlName="bankName">
                            <option *ngFor="let x of bankProvider$" [value]="x?.symbol">
                                {{ x?.name }}</option>
                        </select> -->
            <ng-select appearance="outline" class="ml-2" [searchable]="false" [clearable]="false" labelForId="bankName"
              formControlName="bankName">
              <ng-option *ngFor="let x of bankProvider$" [value]="x?.symbol">
                <img *ngIf="x.symbol != ''" src="../../assets/bank-logo//{{x.symbol}}.png" width="20px" height="20px" />
                {{x?.name}}
              </ng-option>
            </ng-select>
          </div>
          <div class="form-group">
            <label for="name">กรุณากรอกชื่อของคุณให้ตรงตามบัญชี ( ภาษาอังกฤษเท่านั้น
              )</label>
            <input type="text" class="form-control" id="name" formControlName="name" (keyup)="detectLanguage('name')">
          </div>
          <div class="form-group">
            <label for="lastname">กรุณากรอกนามสกุลของคุณให้ตรงตามบัญชี ( ภาษาอังกฤษเท่านั้น
              )</label>
            <input type="text" class="form-control" id="lastname" formControlName="lastname"
              (keyup)="detectLanguage('lastname')">
          </div>
          <div class="form-group">
            <label for="accountNo">กรุณากรอกเลขที่บัญชีของคุณ</label>
            <input type="text" class="form-control" id="accountNo" formControlName="accountNo">
          </div>
          <div class="form-group">
            <label for="username">กรุณากรอก Username ของคุณ</label>
            <input type="text" class="form-control" id="username" formControlName="username" readonly
              value="{{username}}">
          </div>
          <div class="form-group">
            <label for="amount">กรุณากรอกจำนวนเงินที่โอน</label>
            <input type="number" class="form-control" id="amount" formControlName="amount">
          </div>
          <!-- <div class="form-group">
            <label for="reason">เหตุผล</label>
            <select name="reason" id="reason" class="form-control ml-2" formControlName="reason">
              <option value="เนื่องจากท่านไม่ได้โอนเงินตามบัญชีที่เลือกไว้">
                เนื่องจากท่านไม่ได้โอนเงินตามบัญชีที่เลือกไว้</option>
              <option value="เนื่องจากท่านโอนเงินจากบัญชีที่ไม่ได้ลงทะเบียนไว้ในระบบ">
                เนื่องจากท่านโอนเงินจากบัญชีที่ไม่ได้ลงทะเบียนไว้ในระบบ</option>
              <option value="เนื่องจากระบบปิดปรับปรุงชั่วคราว">เนื่องจากระบบปิดปรับปรุงชั่วคราว</option>
              <option value="etc">อื่นๆ</option>
            </select>
          </div> -->
          <!-- <div class="form-group">
            <ng-container *ngIf="f.get('reason').value == 'etc'">
              <label for="reason">กรุณาระบุ</label>
              <textarea class="form-control" id="reason-extra" rows="3" [(ngModel)]="reason"></textarea>
            </ng-container>
          </div> -->
          <div class="content">
            <div class="form-group">
              <label for="sDate">วันที่โอน</label>
              <div class="input-group">
                <input class="form-control" placeholder="กรุณาระบุวันที่" name="sDate" id="sDate" ngbDatepicker
                  #sDate="ngbDatepicker" formControlName="sDate">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary no-shadow" (click)="sDate.toggle()" type="button">
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="time">เวลาที่โอน</label>
              <div class="input-group">
                <ngb-timepicker formControlName="time"></ngb-timepicker>
              </div>
            </div>
            <div class="form-group">
              <label for="file">อัพโหลดสลิป</label>
              <input type="file" class="form-control-file" id="exampleFormControlFile1" (change)="storeFile($event)">
            </div>
          </div>
        </div>
        <div class="footnote">
          <div class="col-12">
            <button type="button" class="btn btn-success" (click)="onSubmit()"
              [disabled]="alerts.type == 'success' || !f.valid">แจ้งปัญหา</button>

            <button type="button" *ngIf="alerts.type == 'success' && authService.getStorageByname('name') == ''"
              class="btn btn-primary" routerLink="/user/login"> คลิกเพื่อติดตามสถานะ </button>


            <button type="button" class="btn btn-primary" *ngIf="authService.getStorageByname('name') != ''"
              routerLink="/user/manage/issue">กลับ</button>
          </div>
          <!--  <div class="col-6 text-center">
            <span style="color: blue;cursor: pointer;" routerLink="/user/login">
              <h4>คลิกเพื่อติดตามสถานะ</h4>
            </span>
            <span *ngIf="alerts.type == 'success' && authService.getStorageByname('name') == ''"
                        style="color: blue;cursor: pointer;" routerLink="/user/login"><h4>คลิกเพื่อติดตามสถานะ</h4></span> 

          </div>-->
        </div>
      </div>
    </form>
  </div>
</div>

<div class="form-group col-md-6">

</div>