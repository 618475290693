import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PaymentCallback, PaymentSupport } from '../models/payment';
import { DFUserBank } from '../models/customer.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  path = `${environment.backendURL}/payment`
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }


  getStatementStatus(transactionID: number) {
    return this.http.get<PaymentCallback>(`${this.path}/check/${transactionID}`, { headers: this.headers }).toPromise()
  }

  getPaymentTransaction(transactionID: string) {
    return this.http.get<any>(`${this.path}/payment/check-transaction/${transactionID}`, { headers: this.headers }).toPromise()
  }

  getPayment(jwt: string) {
    return this.http.post<PaymentCallback>(`${this.path}/payment`, { data: jwt }, { headers: this.headers }).toPromise()
  }

  checkPaymentForSupport(paymentSupport: PaymentSupport) {
    return this.http.post<any>(`${this.path}/check/support`, paymentSupport, { headers: this.headers })
  }

  getVerifiedAccount(jwt: string) {
    return this.http.post<any>(`${this.path}/bankAccounts`, { data: jwt }, { headers: this.headers })
  }

  getVerifiedAccountByUser(jwt: string) {
    return this.http.post<any>(`${this.path}/bankAccountsByUser`, { data: jwt }, { headers: this.headers })
  }

  getDfUserDetailRoute(jwt: string) {
    return this.http.post<any>(`${this.path}/dfUserDetail`, { data: jwt }, { headers: this.headers })
  }

  getBankUnavailable() {
    return this.http.get<any>(`${this.path}/unavailable-user-bank`, { headers: this.headers }).toPromise()
  }

  deleteVerifyiedAccount(jwt: string, id: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        data: jwt,
      },
    };
    return this.http.delete<any>(`${this.path}/bankAccounts/${id}`, options)
  }

  deleteVerifyiedAccountByUser(jwt: string, id: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        data: jwt,
      },
    };
    return this.http.delete<any>(`${this.path}/bankAccountsByUser/${id}`, options)
  }

  registerAccount(jwt: string, bankAccount: DFUserBank) {
    return this.http.post<any>(`${this.path}/bankAccounts-register`, { data: jwt, bankAccount }, { headers: this.headers })
  }

  registerAccountByUser(jwt: string, bankAccount: DFUserBank) {
    return this.http.post<any>(`${this.path}/bankAccounts-registerByUser`, { data: jwt, bankAccount }, { headers: this.headers })
  }

  registerAccountByUserAdmin(jwt: string, bankAccount: DFUserBank, username: string, playerID: string, amount: number, transactionDate: Date, bankAccountID: number) {
    return this.http.post<any>(`${this.path}/bankAccounts-registerByUser/admin`, { data: jwt, bankAccount, username, playerID, amount, transactionDate, bankAccountID }, { headers: this.headers })
  }

  validateJWT(jwt: string) {
    return this.http.post<any>(`${this.path}/validate`, { data: jwt }, { headers: this.headers })
  }

  log(jwt: string) {
    return this.http.post<any>(`${this.path}/log`, { data: jwt }, { headers: this.headers })
  }
}
