import { Pagination } from '../shared/models/pagination.model';
import { AccountVerify } from 'src/app/manage/player-management/player.mode';

export class Support {
    public constructor(init?: Partial<Support>) {
        Object.assign(this, init);
    }

    id: number;
    verifyBankName: string;
    verifyAccountName: string;
    verifyAccountNo: string;
    bankAccountID: number;
    playerID: string;
    dfAmount: number;
    dfTransactionId: number;
    transactionId: string;
    username: string;
    product: string;
    bankName: string;
    accountNo: string;
    accountName: string;
    amount: number;
    name: string;
    lastname: string;
    transactionDate: Date;
    refundBankStatementId: number;
    refundSlip: string;
    depositBankStatementId: number;
    slip: string;
    description: string;
    status: string;
    reason: string;
    createdAt: Date;
    updatedAt: Date;
    updatedBy: string;
    df_username: string;
    verify_id: number;
    bankVerifyList: AccountVerify[]
    txn_name: string;
    txn_lastname: string;
    preVerifyBankName: string;
    preVerifyAccountName: string;
    preVerifyAccountNo: string;
    resultAccountName: string;
    preStatus: string;
    timeDisplay: string;
}

export class SupportResponse {
    support: Support[]
    pagination: Pagination
}