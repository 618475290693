import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModalModule, NgbDatepickerModule, NgbTimepickerModule, NgbAlert, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DfQrModalComponent } from './df-qr-modal/df-qr-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DfIssueModalComponent } from './df-issue-modal/df-issue-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DfStatusModalComponent } from './df-status-modal/df-status-modal.component';

import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    AppComponent,
    DfQrModalComponent,
    DfIssueModalComponent,
    DfStatusModalComponent,
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModalModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireModule,
    NgbAlertModule,
    NgSelectModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
